<template>
  <div class="text-center mt-14">
  <h1 class="text-h1">404 not found</h1>
  <v-btn elevation="12" height="80" width="350" class="mt-12" color="blue lighten-3" to="/login"><h1 class="text-h3">Login?</h1></v-btn>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
export default {
  name: "Login",
  data: () => ({
    valid: true,
   
  }),
  methods: {
    
  },
};
</script>
